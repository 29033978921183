  
  .profile_header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .profile_imageContainer { 
    background-color: #fff;
    border-radius: 50%;
    position: relative;
    width: 120px;
    height: 120px;
    margin: 0 auto;
  }
  
  .profile_image {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .edit_icon {
    position: absolute;
    bottom: 0;
    right: 0; 
    border: none;
    border-radius: 50%;
    padding: 5px;
    cursor: pointer;
  }

  .profile_sections {
    margin: 4% auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
  }
  
  .section {
    margin-bottom: 20px;
    background-color: #fff;
    border-radius: 8px;
  }
  
  .section_header {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 10px 15px;
    background: linear-gradient(180deg, #155f8a 0%, #003366 100%);
    color: #fff;
    border-radius: 8px;
  }
  
  .section_header h2 {
    margin-left: 10px;
    font-size: 1.1rem;
  }
  
  .section_content {
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 0 0 8px 8px;
  }
   
  
  input {
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;  
  }
  
  .success-message {
    color: green;
    margin-bottom: 10px;
  }
  
  .error-message {
    color: red;
    margin-bottom: 10px;
  }
  
  .loading {
    text-align: center;
    margin: 20px 0;
  } 
  .profile_container {
    background-color: #EDEBE1D9;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    min-height: 100vh;
    padding: 20px;
}

.profile_content { 
    border-radius: 10px;
    padding: 20px;
    max-width: 1500px;
    margin-left: 10px auto;
}
body, html {
  height: 100%;
  margin: 0;
  padding: 0;
}

#root {
  min-height: 100%;
}

.name_field {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  color: linear-gradient(180deg, #9A3445 0%, #782333 100%);
  box-shadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)';
  margin-bottom: 10px;
  padding-top: 10px;
  max-width: 500px;
  margin-left: 25px;
}

.name_field label {
  width: 120px;
  margin-right: 10px;
}
.name_field input,
.name_field span {
  flex: 1;
  padding: 5px; 
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  border-radius: 4px;
}

.name_field span {
  display: inline-block;
  min-height: 30px;
  line-height: 30px;
  cursor: pointer;
  color: #41547d;
}

.name_field input:focus {
  outline: none;
  border-color: 'gray';
  box-shadow: 0 0 0 2px rgba(0,123,255,.25);
  border-width: 1;
  background-color: "#fff"; 
  color: #2d30d6;
}

.modalContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.modalContent {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 500px;
}

.modalTitle {
  margin-bottom: 15px;
}

.closeButton {
  margin-top: 15px;
}
.addressSection {
  margin-bottom: 20px;
}

.addressDisplay {
  background-color: #f8f8f8;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 15px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.addressDisplay:hover {
  background-color: #f0f0f0;
}

.formattedAddress {
  margin: 0;
  font-size: 16px;
  line-height: 1.5;
  color: #333;
}

.loadingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100px;
}

.loadingText {
  margin-top: 10px;
  font-size: 14px;
  color: #666;
}

.modalContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  background-color: #6B7280;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 4px 6px rgba(151, 154, 208, 0.164);
}

.modalTitle {
  font-size: 20px;
  margin-bottom: 15px;
  text-align: center;
  color: #666;
}

.closeButton { 
  width: 40%;
  padding: 15px;
  margin-left: 30%;
  margin-top: 15px;
  background-color: #f0f0f0;
  color: #6B7280;
  font-weight: bold;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.addressItem {
  margin-bottom: 10px;
  margin-top: 70px;
  margin-left: 15%;
  width:70%;
  padding: 10px;
  background-color: #ffffff;
  color: #41547d;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

.addressText {
  font-size: 16px;
  margin: 0 0 5px 0;
}

.closeButton:hover {
  background-color: #e0e0e0;
}

.googlePlacesContainer {
  width: 100%;
}

.googlePlacesTextInputContainer {
  width: 100%;
}

.googlePlacesTextInput {
  width: 100%;
  padding: 20px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.googlePlacesListView {
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  overflow: hidden;
}

.modalContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  position: relative;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding-right: 30px;
}

.modalTitle {
  margin: 0;
  font-size: 1.5rem;
  color: #333;
  flex-grow: 1;
}

.modalCloseButton {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #666;
  padding: 5px 10px;
  position: absolute;
  right: 10px;
  top: 10px;
  transition: color 0.2s;
}

.modalCloseButton:hover {
  color: #333;
}

.modalFooter {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  padding-top: 15px;
  border-top: 1px solid #eee;
}

.cancelButton {
  padding: 8px 16px;
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s;
}

.cancelButton:hover {
  background-color: #e0e0e0;
}

.googlePlacesContainer {
  margin: 15px 0;
}

.googlePlacesTextInputContainer {
  width: 100%;
}

.googlePlacesTextInput {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.googlePlacesTextInput:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

.googlePlacesListView {
  margin-top: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
  max-height: 200px;
  overflow-y: auto;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.loadingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.loadingText {
  margin-top: 10px;
  color: #666;
} 
.addressItem {
  cursor: pointer;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.addressItem:hover {
  background-color: #f8f9fa;
}

.addressText {
  margin: 0;
  line-height: 1.5;
}